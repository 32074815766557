import * as React from 'react'
import { Button } from '@mui/material'
import openFinance from '../iframe/init'
import michmanLogo from '../images/michman.svg'

const getTokenPayload = (_token: string) => {
  const informativePart = _token.split('.')[1]
  const payload = JSON.parse(window.atob(informativePart))

  return payload
}

function ResponsiveAppBar({
  accessToken,
  setAccessToken,
}: {
  accessToken: string
  setAccessToken: React.Dispatch<React.SetStateAction<string>>
}) {
  const [username, setUsername] = React.useState('')

  React.useEffect(() => {
    if (accessToken) {
      const tokenPayload = getTokenPayload(accessToken)
      const userId = tokenPayload[`${tokenPayload.aud}/userId`]
      setUsername(userId)
    }
  }, [accessToken])

  return (
    <header
      style={{
        padding: '1rem',
        backgroundColor: 'var(--blue-darker)',
        color: 'var(--grey)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        position: 'sticky',
        top: 0,
        zIndex: 9,
      }}
    >
      <div style={{ display: 'flex' }}>
        <img src={michmanLogo} alt='logo' />
        <h1 style={{ margin: '0 5rem 0 0' }}>
          שלום <span dir='ltr'>{username}</span>
        </h1>
      </div>

      <div style={{ display: 'flex' }}>
        <Button
          variant='contained'
          onClick={() => {
            setAccessToken('')
          }}
          style={{ margin: '0 0.2rem', backgroundColor: 'transparent', border: 'none', boxShadow: 'none' }}
        >
          התנתק
        </Button>
        <Button
          variant='contained'
          onClick={() => {
            openFinance.init()
            openFinance.start()
          }}
          style={{ margin: '0 0.2rem', backgroundColor: 'var(--pink)' }}
        >
          להגשת בקשת אשראי
        </Button>
      </div>
    </header>
  )
}
export default ResponsiveAppBar
