/* variables to replace */
const webUrl = 'https://michman.open-finance.ai'

/* journey window and iframe container */
let iframeContainer = null

/* style helper functions */
const objectToCss = (obj) => Object.keys(obj).map((key) => `${key}: ${obj[key]}`).join(';') + ';'

/* styles */
const iframeStyle = objectToCss({
  'border-width': '0',
  border: '0',
})

const modalStyle = objectToCss({
  position: 'fixed',
  top: '0',
  left: '0',
  'z-index': '2147483647',
  width: '100%',
  height: '100%',
  border: '0',
})

/* functions */
// user controlled functions
function abort() {
  console.log('aborted')
}
function approve(data) {
  console.log('approved', data)
}
function failed(data) {
  console.log('failed', data)
}

// global functions
function closeAll() {
  iframeContainer.remove()
}

/* window post messages listener */
window.addEventListener('message', ({
  origin,
  data,
}) => {
  if (origin !== webUrl || !data || (data.source !== 'waiting' && data.source !== 'journey') || !data.type) {
    return
  }

  const {
    type,
  } = data

  switch (type) {
    case 'close':
      closeAll()
      break
    default:
      break
  }
})

/* main functions  */
const init = (options = {}) => {
  const {
    onApprove = approve,
    onAbort = abort,
    onFailed = failed,
  } = options

  approve = onApprove
  abort = onAbort
  failed = onFailed
}

const start = async () => {
  // modal
  iframeContainer = document.createElement('div')
  iframeContainer.style = modalStyle

  // create the waiting iframe
  const mainIframe = document.createElement('iframe')

  mainIframe.src = webUrl
  mainIframe.style = modalStyle + iframeStyle

  const timer = setInterval(function () {
    if (mainIframe.closed) {
      clearInterval(timer)
      closeAll()
    }
  }, 1000)

  iframeContainer.appendChild(mainIframe)
  document.body.appendChild(iframeContainer)
}

// eslint-disable-next-line no-unused-vars
const openFinance = {
  init,
  start,
}

export default openFinance