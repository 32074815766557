import React, { useState } from 'react'
import MuiAlert, { AlertProps } from '@mui/material/Alert'
import { Avatar, Box, Container, Grow, Link, Snackbar, TextField, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { LockOutlined as LockOutlinedIcon } from '@mui/icons-material'
import sendOtp from '../api/send-otp'
import verifyOtp from '../api/verify-otp'

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

export default function SignIn({
  setAccessToken,
}: {
  setAccessToken: React.Dispatch<React.SetStateAction<string>>
}) {
  const [loading, setLoading] = useState(false)
  const [codeSent, setCodeSent] = useState(false)
  const [alertEvent, setAlertEvent] = useState<{
    type: 'success' | 'info' | 'warning' | 'error'
    message: string
  } | null>(null)

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event?.preventDefault()

    const data = new FormData(event.currentTarget)
    const phone = data.get('phone')
    const otp = data.get('otp')

    console.log({ phone, otp })

    setLoading(true)

    if (!codeSent) {
      if (!phone) {
        setAlertEvent({
          type: 'error',
          message: 'Missing required values',
        })
      } else {
        try {
          await sendOtp(phone as string)

          setCodeSent(true)
          setAlertEvent({
            type: 'success',
            message: 'OTP code successfully sent',
          })
        } catch (e) {
          setAlertEvent({
            type: 'error',
            message: 'Failed to send OTP code, please try again or contact us',
          })
        }
      }
    } else {
      if (!phone || !otp) {
        setAlertEvent({
          type: 'error',
          message: 'Missing required values',
        })
      } else {
        try {
          const { accessToken } = await verifyOtp(phone as string, otp as string)

          setAccessToken(accessToken)
        } catch (e) {
          setAlertEvent({
            type: 'error',
            message: 'Failed to verify OTP code, please try again or contact us',
          })
        }
      }
    }

    setLoading(false)
  }

  const handlePhoneInputFocus = (event: React.FocusEvent) => {
    setCodeSent(false)
  }

  const handleClickAlert = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }

    setAlertEvent(null)
  }

  return (
    <Container
      component='main'
      maxWidth='xs'
      dir='ltr'
      style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
    >
      <br />

      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Avatar style={{ margin: '0.5rem', backgroundColor: 'var(--blue)' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component='h1' variant='h5'>
          Sign in
        </Typography>

        <Box component='form' onSubmit={handleSubmit} noValidate>
          <TextField
            margin='dense'
            fullWidth
            type='tel'
            autoComplete='tel'
            name='phone'
            label='Phone Number'
            required
            autoFocus
            onFocus={handlePhoneInputFocus}
          />
          <Grow in={codeSent} hidden={!codeSent}>
            <TextField
              margin='dense'
              fullWidth
              name='otp'
              label='OTP'
              required
              autoFocus
              inputProps={{ maxLength: 6 }}
            />
          </Grow>

          <LoadingButton
            type='submit'
            loading={loading}
            variant='contained'
            fullWidth
            style={{ marginTop: '1rem', backgroundColor: loading ? 'var(--pink-lighter)' : 'var(--pink)' }}
          >
            {!codeSent ? 'Send Code' : 'Log In'}
          </LoadingButton>
        </Box>
      </div>

      <Typography variant='body2' color='grey' align='center' marginBottom='0.5rem'>
        {'Copyright © '}
        <Link color='inherit' href='https://open-finance.ai' target='_blank'>
          Open Finance
        </Link>
        {` ${new Date().getFullYear()}.`}
      </Typography>

      <Snackbar open={!!alertEvent} onClose={handleClickAlert} autoHideDuration={7000}>
        <Alert onClose={handleClickAlert} severity={alertEvent?.type || 'info'} sx={{ width: '100%' }}>
          {alertEvent?.message || ''}
        </Alert>
      </Snackbar>
    </Container>
  )
}
