import * as React from 'react'
import { Typography } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import getLeads from '../api/get-leads'
import getOpportunities from '../api/get-opportunities'

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 150 },
  { field: 'createdAt', headerName: 'Application Date', width: 150 },
  { field: 'companyName', headerName: 'Company Name', width: 150 },
  { field: 'creditType', headerName: 'Credit Type', width: 150 },
  { field: 'creditAmount', headerName: 'Credit Amount', width: 150 },
  { field: 'numOfCredits', headerName: 'Payments', width: 150 },
  { field: 'caseManager', headerName: 'Case Manager', width: 150 },
  { field: 'applicationStatus', headerName: 'Status', width: 150 },
]

interface TableObject {
  id: string
  createdAt: Date
  companyName: string
  creditType: string
  creditAmount: string | number
  finalCreditAmount?: string | number
  numOfCredits: string | number
  caseManager: string
}

interface LeadTableObject extends TableObject {
  applicationStatus: 'טרם נבדק' | 'בתהליך בדיקה' | 'בדיקה הושלמה בהצלחה' | 'נדחה' | 'נדחה בגלל תנאי סף' | 'הומר'
}

interface OpportunityTableObject extends TableObject {
  applicationStatus:
    | 'השלמת מידע ומסמכים'
    | 'חיתום'
    | 'אישור מנהל אשראי'
    | 'אישור מנכ"ל'
    | 'ועדת אשראי'
    | 'אישור לקוח'
    | 'תפעול - הקמת/עדכון מסמכי לקוח'
    | 'אישור'
    | 'דחייה'
}

export default function DataTable({ accessToken }: { accessToken: string }) {
  const [loading, setLoading] = React.useState(false)
  const [leadItems, setLeadItems] = React.useState<LeadTableObject[]>([])
  const [opportunityItems, setOpportunityItems] = React.useState<OpportunityTableObject[]>([])

  const getLeadsAndOpportunities = React.useCallback(
    async (_token: string) => {
      setLoading(true)

      try {
        const [{ leads }, { opportunities }] = await Promise.all([getLeads(_token), getOpportunities(_token)])

        const leadRows: LeadTableObject[] = leads.map((lead: any) => ({
          id: lead.Id,
          createdAt: new Date(lead.CreatedDate),
          companyName: lead.Company,
          creditType: lead.CreditType__c,
          creditAmount: lead.creditRequested__c,
          finalCreditAmount: lead.FinalLoanAmount__c,
          numOfCredits: lead.Number_of_Payments__c,
          caseManager: lead.SalesManager__c,
          applicationStatus: lead.Status,
        }))

        const opportunityRows: OpportunityTableObject[] = opportunities.map((opportunity: any) => ({
          id: opportunity.Id,
          createdAt: new Date(opportunity.CreatedDate),
          companyName: opportunity.AccountName__c,
          creditType: opportunity.creditType__c,
          creditAmount: opportunity.creditRequested__c,
          finalCreditAmount: opportunity.FinalLoanAmount__c,
          numOfCredits: opportunity.Number_of_Payments__c,
          caseManager: opportunity.SalesManager__c,
          applicationStatus: opportunity.StageName,
        }))

        setLeadItems(leadRows)
        setOpportunityItems(opportunityRows)
      } catch (error) {
        console.error(error)
      }

      setLoading(false)
    },
    [getLeads, getOpportunities]
  )

  React.useEffect(() => {
    getLeadsAndOpportunities(accessToken)
  }, [accessToken])

  const styles = React.useMemo(
    () => ({
      textBoxesWrapper: {
        display: 'flex',
        flexWrap: 'wrap' as const,
        justifyContent: 'space-evenly',
      },
      textBox: {
        margin: '1rem',
        padding: '0.5rem',
        border: '1px solid var(--blue)',
        borderRadius: '1rem',
        color: 'var(--blue-darker)',
        textAlign: 'center' as const,
        boxShadow: '1px 2px 2px 0px grey',
      },
      tableWrapper: {
        height: 400,
        width: '95%',
        margin: '2rem auto',
        display: 'flex',
        flexDirection: 'column' as const,
      },
      tableTitle: {
        marginBottom: '0.5rem',
      },
    }),
    []
  )

  const renderPendingItems = React.useCallback(() => {
    // 1. בקשות בבחינה ראשונית - סה"כ לידים בסטטוס שאינו "הומר" או "נדחה" , להציג ספירה + סה"כ בכסף - סכימה של השדה "סכום האשראי המבוקש" creditRequested__c
    let itemsCount = 0
    let moneyCount = 0

    leadItems.forEach(({ applicationStatus, creditAmount }) => {
      if (applicationStatus !== 'הומר' && applicationStatus !== 'נדחה') {
        itemsCount++
        moneyCount += Number(creditAmount)
      }
    })

    return (
      <div style={styles.textBox}>
        בקשות בבחינה ראשונית
        <br />
        ספירה {itemsCount}
        <br />
        {moneyCount} ש״ח
      </div>
    )
  }, [leadItems, styles])

  const renderActiveItems = React.useCallback(() => {
    // 2. בקשות בתהליך - סה"כ הזדמנויות בשלב שאינו אישור או דחייה, להציג ספירה + סה"כ בכסף - סכימה של השדה "סכום האשראי המבוקש" creditRequested__c
    let itemsCount = 0
    let moneyCount = 0

    opportunityItems.forEach(({ applicationStatus, creditAmount }) => {
      if (applicationStatus !== 'אישור' && applicationStatus !== 'דחייה') {
        itemsCount++
        moneyCount += Number(creditAmount)
      }
    })

    return (
      <div style={styles.textBox}>
        בקשות בתהליך
        <br />
        ספירה {itemsCount}
        <br />
        {moneyCount} ש״ח
      </div>
    )
  }, [opportunityItems, styles])

  const renderApprovedItems = React.useCallback(() => {
    // 3. בקשות מאושרות - סה"כ הזדמנויות בשלב "אישור", להציג ספירה + סה"כ בכסף - סכימה של השדה "סכום" FinalLoanAmount__c
    let itemsCount = 0
    let moneyCount = 0

    opportunityItems.forEach(({ applicationStatus, creditAmount }) => {
      if (applicationStatus === 'אישור') {
        itemsCount++
        moneyCount += Number(creditAmount)
      }
    })

    return (
      <div style={styles.textBox}>
        בקשות מאושרות
        <br />
        ספירה {itemsCount}
        <br />
        {moneyCount} ש״ח
      </div>
    )
  }, [opportunityItems, styles])

  const renderIncompleteItems = React.useCallback(() => {
    // 4. בקשות ממתינות להשלמת מסמכים - סה"כ הזדמנויות בשלב "השלמת מדיע ומסמכים"
    let itemsCount = 0

    opportunityItems.forEach(({ applicationStatus }) => {
      if (applicationStatus === 'השלמת מידע ומסמכים') {
        itemsCount++
      }
    })

    return (
      <div style={styles.textBox}>
        {itemsCount} בקשות ממתינות
        <br />
        להשלמת מסמכים
      </div>
    )
  }, [opportunityItems, styles])

  return (
    <React.Fragment>
      <div style={styles.textBoxesWrapper}>
        {renderPendingItems()}
        {renderActiveItems()}
        {renderApprovedItems()}
        {renderIncompleteItems()}
      </div>

      <div style={styles.tableWrapper}>
        <Typography variant='h5' color='var(--blue-darker)' fontWeight='bold' style={styles.tableTitle}>
          בקשות בבחינה ראשונית
        </Typography>
        <DataGrid rows={leadItems} columns={columns} pageSize={5} loading={loading} rowsPerPageOptions={[5]} />
      </div>

      <div style={styles.tableWrapper}>
        <Typography variant='h5' color='var(--blue-darker)' fontWeight='bold' style={styles.tableTitle}>
          בקשות בתהליך
        </Typography>
        <DataGrid
          rows={opportunityItems}
          columns={columns}
          pageSize={5}
          loading={loading}
          rowsPerPageOptions={[5]}
        />
      </div>
    </React.Fragment>
  )
}
