import axios from 'axios'
import { LOAN_SERVICE_URL } from '../consts'

const getLeads = async (accessToken: string): Promise<any> => {
  const response = await axios.get(`${LOAN_SERVICE_URL}/related-leads `, {
    headers: {
      authorization: `Bearer ${accessToken}`
    }
  })

  return response.data
}

export default getLeads