import * as React from 'react'
import SignIn from './components/SignIn'
import ResponsiveAppBar from './components/ResponsiveAppBar'
import DataTable from './components/DataTable'

const App = () => {
  const [accessToken, setAccessToken] = React.useState('')
  // Logged out === EMPTY STRING
  // Logged in === TOKEN STRING

  return !accessToken ? (
    <SignIn setAccessToken={setAccessToken} />
  ) : (
    <React.Fragment>
      <ResponsiveAppBar accessToken={accessToken} setAccessToken={setAccessToken} />
      <DataTable accessToken={accessToken} />
    </React.Fragment>
  )
}

export default App
